<template>
    <div class="placeholder" :style="{width, height, borderRadius}"></div>
</template>

<script>
export default {
    name: "Placeholder",
    props: {
        width: String,
        height: String,
        borderRadius: {
            type: String,
            default: "0.3rem"
        }
    }
}
</script>

<style>
.placeholder{
    animation: placeholder-glow 3s linear infinite;
}
@keyframes placeholder-glow {
    0%, 100%{
        background-color: var(--background-light-2);
    }
    50%{
        background-color: var(--background-light-3);
    }
}
</style>