import JSZip from "jszip";

export class FileTree {

    static async create(files){
        const fileTree = new FileTree();
        await fileTree._compile(files);
        return fileTree;
    }



    _data = null;

    _fileCount = 0;
    _dirCount = 0;

    get(){
        return this._data;
    }
    getFileCount(){
        return this._fileCount;
    }
    getDirectoryCount(){
        return this._dirCount;
    }

    async zip(onUpdate = undefined){
        const zip = JSZip();
        for(const obj of this.get()){
            this._zipAddObject(obj, zip);
        }

        const options = {
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: {
                level: 6
            }
        };

        const file = await zip.generateAsync(options, onUpdate).catch((error) => console.log(error));
        file.name = "Archive.zip";
        return file;
    }
    _zipAddObject(obj, folder){
        if(obj.file){
            // File
            folder.file(obj.item.name, obj.item);
        } else {
            // Directory
            const dir = folder.folder(obj.item.name);
            if(obj.children.length > 0){
                for(const child of obj.children){
                    this._zipAddObject(child, dir)
                }
            }
        }
    }

    async _compile(files){
        this._data = [];

        const fileSystemEntries = [];
        for(const file of Array.from(files)){
            if(typeof file.webkitGetAsEntry === 'function'){
                const fileSystemEntry = file.webkitGetAsEntry()
                if(fileSystemEntry){
                    fileSystemEntries.push(fileSystemEntry);
                }
            } else {
                this._data.push({file: true, directory: false, item: file});
                this._fileCount++;
            }
        }

        for(const fileSystemEntry of fileSystemEntries){
            this._data.push(await this._compileFileSystemEntry(fileSystemEntry));
        }
    }
    async _compileFileSystemEntry(fileSystemEntry){
        const data = {file: fileSystemEntry.isFile, directory: fileSystemEntry.isDirectory};

        if (fileSystemEntry.isFile) {
            data.item = await new Promise((resolve) => fileSystemEntry.file((file) => resolve(file)));
            this._fileCount++;
        } else if (fileSystemEntry.isDirectory) {
            const children = [];
            const dirReader = fileSystemEntry.createReader();
            while(true){
                const entries = await new Promise((resolve) => dirReader.readEntries((entries) => resolve(entries)));
                if(entries.length > 0){
                    for(const entry of entries){
                        children.push(await this._compileFileSystemEntry(entry));
                    }
                } else {
                    break;
                }
            }

            data.item = fileSystemEntry;
            data.children = children;
            this._dirCount++;
        }
        return data;
    }

}