<template>
    <div v-if="modelValue" class="dropdown">
        <DropdownOption v-if="active" class="active" :option="active" @click.prevent="show = !show">
            <div class="dropdown-icon">
                <ArrowSvg :class="{'rotate': show}"/>
            </div>
        </DropdownOption>

        <ul v-show="show" class="dropdown-options">
            <DropdownOption
                v-for="(option, index) of options"
                :key="index"
                :option="option"
                @click.prevent="update(option.value)"
            />
        </ul>
    </div>
</template>

<script>
import DropdownOption from "@/components/input/Dropdown/DropdownOption";
import ArrowSvg from "@/components/svg/ArrowSvg";
export default {
    name: "Dropdown",
    components: {ArrowSvg, DropdownOption},
    props: {
        options: Array,
        modelValue: null
    },
    emits: ['update', 'update:modelValue'],
    data: () => ({
        show: false
    }),
    computed: {
        active: function(){
            for(const option of this.options){
                if(option.value === this.modelValue)
                    return option;
            }
            return null;
        }
    },
    methods: {
        update(value){
            this.show = false;
            this.$emit('update:modelValue', value);
            this.$emit('update', value);
        }
    }
}
</script>

<style>
    .dropdown{
        position: relative;
        width: 8rem;
        user-select: none;
    }
    .dropdown .dropdown-options{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;

        list-style: none;
        padding: 0;
        margin: 0.5rem 0 0;

        overflow: hidden;
    }
    .dropdown .dropdown-options,
    .dropdown .dropdown-option.active{
        border-radius: 0.3rem;
    }
    .dropdown .dropdown-option{
        position: relative;
        display: flex;
        align-items: center;
        height: 1.8rem;
        padding: 0.5rem 1rem;
        background-color: var(--background-light-2);

        font-size: 0.75rem;
    }
    .dropdown .dropdown-option:hover{
        cursor: pointer;
        background-color: var(--background-light-3);
    }
    .dropdown .dropdown-options .dropdown-option:not(:last-child){
        border-bottom: 1px solid rgba(255,255,255,0.05);
    }

    .dropdown .dropdown-icon{
        position: absolute;
        right: 0.5rem;
        top: calc(50% - (1rem / 2));
    }
    .dropdown .dropdown-icon svg{
        height: 1rem;
        width: auto;
        transition: 0.2s ease;
    }
    .dropdown .dropdown-icon svg.rotate{
        transform: rotateZ(180deg);
    }

    .dropdown .dropdown-option img{
        height: 1.2em;
        width: auto;
        margin-right: 0.4rem;
    }
    .dropdown .dropdown-option img.round{
        border-radius: 50%;
        width: 1.2em;
        object-position: center;
        object-fit: cover;
    }
</style>