<template>
    <div class="controls">
        <slot></slot>
    </div>
</template>

<style>
.controls{
    position: relative;
    display: flex;
}
.controls button{
    outline: 0;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    color: var(--gray);
    background-color: var(--background-light-1);
    transition: 0.1s ease;
}
.controls button svg{
    height: 1.5rem;
    width: auto;
}
.controls button:hover{
    cursor: pointer;
    color: var(--light-gray);
    background-color: var(--background-light-2);
}
.controls button:active{
    transform: scale(0.8);
}

.controls.small button{
    width: 1.75rem;
    height: 1.75rem;
}
.controls.light button{
    background-color: var(--background-light-2);
}
.controls.light button:hover{
    background-color: var(--background-light-3);
}
</style>