<template>
    <div :class="['estimated-time', {'invisible': progress >= 100}]">
        {{ $t('estimated_time') }}: <div class="live"></div><strong>{{formatted_estimated_time}}</strong>
    </div>
</template>

<script>
export default {
    name: "EstimatedTime",
    props: {
        progress: Number,
        start_time: Number
    },
    data: () => ({
        formatted_estimated_time: "...",
        tasks: {
            checker: null
        }
    }),
    methods: {
        updateFormattedEstimatedTime(){
            const now = this.time();

            const time = now - this.start_time;
            const remainingTime = (time/this.progress)*(100-this.progress);

            const calculators = [
                {
                    codes: ['day', 'days'],
                    diff: (time) => (time / (60 * 60 * 24))
                },
                {
                    codes: ['hour', 'hours'],
                    diff: (time) => (time / (60 * 60))
                },
                {
                    codes: ['minute', 'minutes'],
                    diff: (time) => (time / (60))
                },
                {
                    codes: ['second', 'seconds'],
                    diff: (time) => (time)
                }
            ];

            let code = 'second';
            let val = 1;
            for(const calc of calculators){
                const diff = Math.round(calc.diff(remainingTime));
                if(diff >= 1){
                    code = (diff > 1)? calc.codes[1] : calc.codes[0];
                    val = diff;
                    break;
                }
            }

            if(Number.isInteger(val)){
                this.formatted_estimated_time = this.$t(code, [val]);
            }
        }
    },
    mounted() {
        this.tasks.checker = setInterval(() => this.updateFormattedEstimatedTime(), 2000);
    },
    unmounted() {
        if(this.tasks.checker){
            clearInterval(this.tasks.checker);
        }
    }
}
</script>

<style>
.estimated-time{
}
.estimated-time .live{
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    background-color: var(--yellow);
    border-radius: 50%;
    margin: 0 0.25em 0.15em 0.4em;
    animation: estimated-time-live 1.8s linear infinite;
}
@keyframes estimated-time-live {
    0%, 100%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
}
</style>