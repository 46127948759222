<template>
    <div class="cprogress-container">
        <div class="cprogress-background-container">
            <div class="cprogress-background">
                <div class="cprogress-background-circle"></div>
                <UploadOutlineSvg class="cprogress-icon"/>
                <div class="cprogress-text">{{progress}}%</div>
            </div>
        </div>
        <div class="cprogress-progress-container" :style="{height: `${progress}%`}">
            <div class="cprogress-progress">
                <div class="cprogress-progress-circle"></div>
                <UploadSvg class="cprogress-icon"/>
                <div class="cprogress-text">{{progress}}%</div>
            </div>
        </div>
        <div class="cprogress-outline">
            <CircleProgress :percent="progress" :size="200" :border-width="5" :border-bg-width="0" fill-color="var(--yellow)"/>
        </div>
    </div>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import UploadSvg from "@/components/svg/UploadSvg";
import UploadOutlineSvg from "@/components/svg/UploadOutlineSvg";
export default {
    name: "ProgressCircle",
    components: {CircleProgress, UploadOutlineSvg, UploadSvg},
    props: {
        progress: Number
    }
}
</script>

<style>
.cprogress-container{
    position: relative;
    height: 8rem;
    width: 8rem;
}
.cprogress-outline{
    position: absolute;
    z-index: 0;
    top: -1rem;
    left: -1rem;
    transform: scale(-1);
}
.cprogress-background-container,
.cprogress-progress-container{
    position: relative;
    height: 100%;
    width: 100%;
}
.cprogress-progress-container{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease;
}
.cprogress-background,
.cprogress-progress{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    position: relative;
    z-index: 1;
}
.cprogress-progress{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 8rem;
    width: 8rem;
}
.cprogress-background-circle,
.cprogress-progress-circle{
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--background-light-1);

    animation: cprogress-animation 5s linear infinite;
}
.cprogress-progress-circle{
    background-color: var(--yellow);
}
.cprogress-icon,
.cprogress-text{
    position: relative;
    z-index: 1;
    color: var(--yellow);
}
.cprogress-icon{
    height: 4rem;
    width: auto;
}
.cprogress-text{
    font-weight: 700;
}
.cprogress-progress .cprogress-icon{
    color: var(--background);
}
.cprogress-progress .cprogress-text{
    color: var(--background);
}
@keyframes cprogress-animation {
    0%, 100%{
        transform: scale(1);
    }
    50%{
        transform: scale(0.9);
    }
}
</style>