import { createI18n } from "vue-i18n";

import en from "@/i18n/en";
import de from "@/i18n/de";

let lang = window.localStorage.getItem('lang');
if(!lang){
    const browserLang = navigator.language || navigator.userLanguage;
    lang = browserLang.split('-')[0];
}

const options = {
    locale: lang,
    fallbackLocale: 'en',
    messages: {
        en,
        de
    }
};

export default createI18n(options);