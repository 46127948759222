<template>
    <div class="upload-container">
        <label :for="(!upload.preparing && !upload.running)? 'file' : '#'" class="upload-label">
            <!-- if -->
            <transition name="switch">
                <div v-if="upload.running" class="upload-content">
                    <EstimatedTime v-if="upload.since" :progress="upload.progress" :start_time="upload.since" class="fs-16 c-light-gray mb-4"/>
                    <ProgressCircle :progress="upload.progress"/>
                    <Controls class="mt-4">
                        <button type="button" @click.prevent="cancelUpload">
                            <CloseSvg/>
                        </button>
                    </Controls>
                </div>
            </transition>
            <!-- else -->
            <transition name="switch">
                <div v-if="!upload.running" class="upload-content">
                    <template v-if="!upload.preparing">
                        <div class="upload-icon-container">
                            <div class="upload-icon-background"></div>
                            <LogoSvg class="upload-icon" style="margin-top:0.3rem;"/>
                        </div>

                        <h1 class="fs-40 fw-bold text-center mt-4 mb-0">{{ $t('upload_file') }}</h1>
                    </template>
                    <template v-else-if="upload.preparing && !upload.zip">
                        <div class="upload-icon-container">
                            <div class="upload-icon-background"></div>
                            <LogoSvg class="upload-icon" style="margin-top:0.3rem;"/>
                        </div>

                        <div class="upload-prepare-loader"></div>
                    </template>
                    <CompilerProgress v-else :from_count="upload.zip.file_count" :progress="upload.zip.progress"/>
                </div>
            </transition>
        </label>
        <input id="file" type="file" style="display:none;" multiple @input="handleInput"/>
    </div>
    <SharePopup v-if="upload.running" :token="upload.token" />
</template>

<script>
import { uploadBytesResumable } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { firestore, storageRef } from "@/firebase";
import ProgressCircle from "@/components/ProgressCircle";
import UploadOutlineSvg from "@/components/svg/UploadOutlineSvg";
import CloseSvg from "@/components/svg/CloseSvg";
import LogoSvg from "@/components/svg/LogoSvg";
import SharePopup from "@/components/SharePopup";
import Controls from "@/components/Controls";
import EstimatedTime from "@/components/EstimatedTime";
import {FileTree} from "@/components/helper/File";
import CompilerProgress from "@/components/CompilerProgress";

export default {
    name: "Upload",
    components: {
        CompilerProgress,
        EstimatedTime, Controls, SharePopup, LogoSvg, CloseSvg, UploadOutlineSvg, ProgressCircle},
    data: () => ({
        upload: {}
    }),
    watch: {
        '$root.store.files': function(files){
            if(files){
                this.importStoredFiles();
            }
        }
    },
    methods: {
        initUploadData(){
            this.upload = {
                preparing: false,
                running: false,
                progress: 0,
                task: null,
                token: null,
            };
        },
        cancelUpload(){
            if(this.upload.running && this.upload.task){
                this.upload.task.cancel();
                this.initUploadData();
            }
        },
        importStoredFiles(){
            if(this.$root.store.files && this.$root.store.files.length > 0){
                this.handleFiles(this.$root.store.files);
                this.$root.store.files = null;
            }
        },
        handleInput(event){
            this.handleFiles(event.target.files);
        },
        async handleFiles(files){
            this.upload.preparing = true;

            const fileTree = await FileTree.create(files);
            if(fileTree.getFileCount() > 0){

                let file;
                if(fileTree.getFileCount() > 1){
                    this.upload.zip = {
                        file_count: fileTree.getFileCount(),
                        progress: 0
                    };
                    file = await fileTree.zip((event) => this.upload.zip.progress = Math.trunc(event.percent));
                    this.upload.zip = false;
                } else {
                    file = fileTree.get()[0].item;
                }

                this.uploadFile(file);
            }
        },
        async uploadFile(file){
            this.upload.preparing = true;
            const response = await this.apiRequest(`/upload/prepare`, {
                method: 'post',
                data: {
                    access_token: this.$root._auth.accessToken,
                    file: this.getFileData(file)
                }
            });
            this.upload.preparing = false;

            if(response){
                const fileData = response.file;

                this.upload.token = fileData.token;
                this.upload.running = true;
                this.upload.since = this.time();

                const updateProgressRef = doc(firestore, `uploads/${fileData.token}/data/progress`);
                const updateProgress = async (data) => {
                    await updateDoc(updateProgressRef, data).catch(e => console.log(e));
                }

                const ref = storageRef(`upload/${response.upload_at}/${fileData.token}/${file.name}`);
                this.upload.task = uploadBytesResumable(ref, file);
                this.upload.task.on('state_changed', (snapshot) => {
                    const progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.upload.progress = progress;

                    updateProgress({
                        value: progress,
                        bytes: snapshot.bytesTransferred
                    });
                }, (error) => {
                    updateProgress({
                        canceled: true
                    });

                    this.initUploadData();
                }, () => {
                    console.log(true);
                });
            }
        },
        getFileData(file){
            return {
                name: file.name,
                size: file.size
            };
        }
    },
    beforeMount(){
        this.initUploadData();
    },
    mounted() {
        this.importStoredFiles();
    }
}
</script>

<style>
.upload-container{
    position: relative;
    width: 100%;
    height: var(--min-content-height);
    padding: 2rem;

    display: flex;
    flex-direction: column;
}
.upload-label,
.upload-content{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upload-label{
    flex: 1;
    width: 100%;
    height: 100%;
    border: 0.5rem dotted var(--background-light-1);
    border-radius: 2rem;
    transition: 0.2s ease;
}
.upload-label:hover{
    cursor: pointer;
    border-color: var(--background-light-2);
}
.upload-icon-container{
    position: relative;
    height: 8rem;
    width: 8rem;
    padding: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.upload-icon{
    position: relative;
    z-index: 1;
    height: 4rem;
    width: 4rem;
    color: var(--yellow);
}
.upload-icon-background{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--background-light-1);

    animation: throb 5s linear infinite;
}
@keyframes throb {
    0%, 100%{
        transform: scale(1);
    }
    50%{
        transform: scale(0.9);
    }
}

.upload-prepare-loader{
    position: absolute;
    z-index: -1;
    width: 12rem;
    height: 12rem;
    background-color: var(--yellow);
    border-radius: 50%;
    animation: prepare-pulse 1.2s ease infinite;
}
@keyframes prepare-pulse {
    0%{
        opacity: 0.4;
        transform: scale(0.4);
    }
    100%{
        opacity: 0;
        transform: scale(1);
    }
}



.switch-enter-active,
.switch-leave-active{
    transition: all 0.3s ease;
}
.switch-leave-active{
    position: absolute;
}
.switch-enter-from,
.switch-leave-to {
    transform: translateX(-20rem) translateY(-10rem) rotateX(45deg);
    opacity: 0;
}
.switch-leave-to{
    transform: translateX(20rem) translateY(10rem) rotateX(-45deg);
}
</style>