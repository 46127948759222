<template>
    <footer>
        <div class="py-3">
            <p class="fs-14 text-center m-0"><HeartSvg class="inline-icon"/> {{ $t('thanks_for_using') }}</p>
        </div>
        <div class="footer-content">
            <div class="fs-14 text-center">
                © 2022 <router-link :to="{name: 'upload'}" class="c-hover-yellow">filezapp.com</router-link> | {{ $t('copyright_suffix') }}
            </div>
        </div>
    </footer>
</template>

<script>
import HeartSvg from "@/components/svg/HeartSvg";
export default {
    name: "Footer",
    components: {HeartSvg}
}
</script>

<style>
footer{
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 5rem;
    padding: 0 2rem;
    margin-top: 2rem;

    color: var(--dark-gray);
}
footer .footer-content{
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid rgba(255,255,255,0.025);
}
</style>