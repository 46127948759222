<template>
    <button type="button" class="btn">
        <div v-if="$slots.icon" class="icon">
            <slot name="icon"></slot>
        </div>

        <span class="text">
        <slot name="default"></slot>
    </span>
    </button>
</template>

<script>
export default {
    name: "Button"
}
</script>

<style>
.btn{
    --border-radius: 0.3rem;

    position: relative;
    appearance: none;
    outline: 0!important;
    border: 0;
    padding: 0;
    height: 1.6rem;
    color: white;
    background-color: var(--background-light-2);
    border-radius: var(--border-radius);
    font-weight: bold;
    font-size: 0.8rem;
    font-family: var(--font);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s ease;
}
.btn .text{
    display: inline-block;
    padding: 0.4rem 1rem;
}
.btn:hover{
    cursor: pointer;
}
.btn:active{
    transform: scale(0.95);
}

.btn .icon{
    position: relative;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    margin-right: -0.5rem;
}
.btn .icon svg{
    position: relative;
    z-index: 1;
    height: 1.2em;
    width: auto;
}
.btn .icon::before{
    content: "";
    position: absolute;
    z-index: 0;
    top: -70%;
    left: -100%;
    width: 200%;
    height: 240%;
    background-color: rgba(0,0,0,0.1);
    border-radius: 50%;
}

.btn.large{
    font-size: 1.2rem;
    height: 2.8rem;
    --border-radius: 0.5rem;
}
.btn.large .text{
    padding: 0.6rem 2.5rem;
}
.btn.large .icon{
    padding: 0 1.25rem;
    margin-right: -1.25rem;
}

.btn:disabled{
    background-color: var(--dark-gray)!important;
}

.btn.yellow{
    background-color: var(--yellow);
    color: var(--background);
}
.btn.yellow:hover{
    background-color: var(--dark-yellow);
}
</style>