<template>
    <Dropdown
        :options="lang"
        @update="update"
        v-model="active"
    />
</template>

<script>
import Dropdown from "@/components/input/Dropdown";
export default {
    name: "Language",
    components: {Dropdown},
    data: () => ({
        active: 'en',
        lang: [
            {value: 'en', label: 'English', img: {src: '/img/lang/en.svg', alt: 'English', class: 'round'}},
            {value: 'de', label: 'Deutsch', img: {src: '/img/lang/de.svg', alt: 'Deutsch', class: 'round'}},
        ]
    }),
    methods: {
        update(lang){
            this.$i18n.locale = lang;
            document.documentElement.lang = lang;
            window.localStorage.setItem('lang', lang);
        }
    },
    beforeMount() {
        this.active = this.$i18n.locale;
    },
    mounted() {
        this.update(this.active);
    }
}
</script>

<style scoped>

</style>