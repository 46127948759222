<template>
    <header>
        <div class="row w-100 h-100">
            <div class="col-4 d-flex justify-content-start align-items-center h-100">
                <router-link :to="{name: 'upload'}">
                    <Button :class="['yellow', {'invisible': $route.name === 'upload'}]">
                        <template #icon>
                            <LogoSvg/>
                        </template>

                        {{ $t('upload_file') }}
                    </Button>
                </router-link>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center h-100">
                <router-link :to="{name: 'upload'}" class="logo-container">
                    <img class="logo" src="/img/logo-writing.svg" alt="Filez Logo">
                </router-link>
            </div>
            <div class="col-4 d-flex justify-content-end align-items-center h-100">
                <Language/>
            </div>
        </div>
    </header>
</template>

<script>
import Language from "@/components/input/Language";
import Button from "@/components/input/Button";
import FileSvg from "@/components/svg/FileSvg";
import LogoSvg from "@/components/svg/LogoSvg";
export default {
    name: "Header",
    components: {LogoSvg, FileSvg, Button, Language}
}
</script>

<style>
header{
    position: relative;
    z-index: 10;
    width: 100%;
    height: var(--header-height);
    background-color: var(--background-light-1);

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
}
header .logo-container{
    position: relative;
    height: 100%;
    padding: 0 1rem;
    background-color: rgba(255,255,25,0.05);

    display: flex;
    align-items: flex-end;
    transition: 0.2s ease;
}
header .logo-container:hover{
    background-color: rgba(255,255,25,0.1);
}
header .logo{
    height: 2.5rem;
    width: auto;
}
</style>