<template>
    <div class="container download-container">
        <div class="d-flex justify-content-center align-items-end flex-wrap mb-5">
            <!-- Placeholder -->
            <template v-if="!init">
                <div class="file-type-container mb-3">
                    <FileSvg class="file-icon loading"/>
                </div>
                <div class="file-information mb-3">
                    <div class="d-flex justify-content-between align-items-center flex-wrap mb-3">
                        <Placeholder width="3rem" height="1rem"/>
                        <Placeholder width="6rem" height="1rem"/>
                    </div>
                    <Placeholder width="30rem" height="2rem" class="mb-3"/>
                </div>
            </template>
            <!-- File does not exist -->
            <template v-else-if="!file.exists">
                <div class="file-type-container mb-3">
                    <FileSvg class="file-icon loading"/>
                    <h2 class="file-type not-exist">
                        :(
                    </h2>
                </div>
                <div class="file-information mb-3">
                    <h1 class="file-name c-light-gray">File does not exist</h1>
                </div>
            </template>
            <!-- Upload canceled- -->
            <template v-else-if="is_canceled">
                <div class="file-type-container mb-3">
                    <FileSvg class="file-icon loading"/>
                    <h2 class="file-type">
                        .{{getFileType(file.data.name)}}
                    </h2>
                </div>
                <div class="file-information mb-3">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div class="c-dark-gray">{{getFormattedFileSize(file.data.size)}}</div>
                    </div>
                    <h1 class="file-name c-gray text-decoration-line-through">{{file.data.name}}</h1>
                </div>
            </template>
            <!-- File exists -->
            <template v-else>
                <div class="file-type-container mb-3">
                    <FileSvg class="file-icon"/>
                    <h2 class="file-type">
                        .{{getFileType(file.data.fileName || file.data.name)}}
                    </h2>
                </div>
                <div class="file-information mb-3">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div class="c-gray">{{getFormattedFileSize(file.data.size)}}</div>
                        <div class="c-gray">{{getFormattedUploadedAt(file.data.uploaded_at || null)}}</div>
                    </div>
                    <h1 class="file-name">{{file.data.fileName || file.data.name}}</h1>
                </div>
            </template>
        </div>

        <!-- Placeholder -->
        <div v-if="!init" class="d-flex justify-content-center mb-5">
            <Placeholder width="16rem" height="2.8rem" border-radius="0.5rem" class="mb-3"/>
        </div>
        <template v-else-if="file.exists">
            <!-- Ready -->
            <div v-if="is_ready" class="d-flex flex-column align-items-center justify-content-center mb-5">
                <a :href="file.url" :download="file.data.fileName">
                    <Button class="large yellow mb-5" :disabled="file.url === null" title="Download">
                        <template #icon>
                            <LogoSvg/>
                        </template>
                        {{ $t('download') }}
                    </Button>
                </a>

                <div class="limited mb-5">
                    <Preview :file_url="file.url" :content_type="file.data.content_type"/>
                </div>
            </div>
            <!-- Uploading -->
            <div v-else-if="!is_canceled" class="mb-5">
                <div class="download-uploading-progress-container mx-auto mb-2">
                    <div class="download-uploading-progress-bar">
                        <div class="download-uploading-progress" :style="{width: `${file.progress.value}%`}"></div>
                    </div>
                    <div class="download-uploading-text">
                        {{ $t('progress') }} <strong class="c-yellow">{{file.progress.value}}%</strong>
                    </div>
                </div>

                <template v-if="is_uploading">
                    <EstimatedTime :progress="file.progress.value" :start_time="file.progress.created_at" class="fs-16 c-gray text-center mb-4"/>
                    <!--
                    <p class="fs-16 c-gray text-center m-0 mb-4">
                        {{getFormattedFileSize(file.progress.bytes)}} / {{getFormattedFileSize(file.progress.total_bytes)}}
                    </p>
                    -->
                    <p class="fs-16 c-gray text-center m-0">
                        {{ $t('file_is_uploading') }}
                    </p>
                </template>
                <p v-else class="fs-16 c-gray text-center m-0">
                    {{ $t('almost_ready') }}
                </p>
            </div>
            <div v-else class="d-flex justify-content-center mb-5">
                <p class="fs-20 c-light-gray">
                    <HeartBrokenSvg class="inline-icon"/>
                    {{ $t('upload_canceled') }}
                </p>
            </div>
        </template>
    </div>
</template>

<script>
//import axios from "axios";
//import {saveAs} from "file-saver";
import {firestore} from "@/firebase";
import {doc, onSnapshot} from "firebase/firestore";
import FileSvg from "@/components/svg/FileSvg";
import Button from "@/components/input/Button";
import LogoSvg from "@/components/svg/LogoSvg";
import Placeholder from "@/components/Placeholder";
import HeartBrokenSvg from "@/components/svg/HeartBrokenSvg";
import EstimatedTime from "@/components/EstimatedTime";
import Preview from "@/components/Preview";
export default {
    name: "Download",
    components: {Preview, EstimatedTime, HeartBrokenSvg, Placeholder, LogoSvg, Button, FileSvg},
    data: () => ({
        init: false,
        file: {
            exists: false,
            progress: null,
            data: null,
            url: null
        },
        flags: {
            downloading: false
        },
        tasks: {
            snapshot: null,
            progress_snapshot: null
        }
    }),
    computed: {
        is_uploading: function(){
            return !this.is_ready && !this.is_canceled && this.file.progress && this.file.progress.value < 100;
        },
        is_ready: function(){
            return this.file.data && this.file.data.name && this.file.data.uploaded_at;
        },
        is_canceled: function(){
            return this.file.progress && this.file.progress.canceled;
        }
    },
    watch: {
        is_ready: function(value){
            if(value){
                if(this.tasks.progress_snapshot){
                    this.tasks.progress_snapshot(); // Unsubscribe
                }
                if(!this.file.url){
                    this.requestDownloadUrl();
                }
            }
        }
    },
    methods: {
        _init(){
            this.file.exists = this.file.data !== false;

            if(this.file.exists){
                if(!this.file.data.uploaded_at){
                    if(!this.tasks.progress_snapshot){
                        this.tasks.progress_snapshot = onSnapshot(doc(firestore, `uploads/${this.$route.params.token}/data/progress`), (doc) => {
                            this.file.progress = (doc.exists())? doc.data() : false;

                            if(!this.init) {
                                this.init = true;
                            }
                        });
                    }
                    return;
                }
            }

            this.init = true;
        },
        async requestDownloadUrl(){
            const response = await this.apiRequest(`/download/${this.$route.params.token}/url?`, {
                params: {access_token: this.$root._auth.accessToken}
            });

            if(response){
                this.file.url = response.url;
            }
        },
        /*
        async download(){
            if(!this.flags.downloading){
                this.flags.downloading = true;
                const r = await axios.get(this.file.url, {
                    responseType: 'blob',
                    timeout: 30000,
                    onDownloadProgress: progressEvent => {
                        const total = progressEvent.total;
                        const current = progressEvent.loaded

                        let percentCompleted = Math.floor(current / total * 100)
                        console.log('completed: ', percentCompleted)
                    }
                }).catch(e => console.log(e));

                if(r && r.data){
                    saveAs(r.data, this.file.data.fileName);
                }
                this.flags.downloading = false;
            }
        },
        */
        getFileType(fileName){
            const s = fileName.split('.');
            return s[s.length-1];
        },
        getFormattedFileSize(fileSize){
            let val = fileSize;
            let suffix = "B";

            if(val > 1024){
                val /= 1024;
                suffix = "KB";
            }
            if(val > 1024){
                val /= 1024;
                suffix = "MB";
            }
            if(val > 1024){
                val /= 1024;
                suffix = "GB";
            }

            return Number(val).toFixed(2) + suffix;
        },
        getFormattedUploadedAt(fileUploadedAt){
            let code = 'minute_ago';
            let val = 1;

            if(fileUploadedAt){
                const now = new Date();
                const uploadedAt = new Date(fileUploadedAt * 1000);

                const calculators = [
                    {
                        codes: ['year_ago', 'years_ago'],
                        diff: (d1, d2) => {
                            return d2.getFullYear() - d1.getFullYear();
                        }
                    },
                    {
                        codes: ['month_ago', 'months_ago'],
                        diff: (d1, d2) => {
                            return d2.getMonth() - d1.getMonth() + ((d2.getFullYear() - d1.getFullYear()) * 12);
                        }
                    },
                    {
                        codes: ['day_ago', 'days_ago'],
                        diff: (d1, d2) => {
                            const diff = d2.getTime() - d1.getTime();
                            return diff / (1000 * 60 * 60 * 24);
                        }
                    },
                    {
                        codes: ['hour_ago', 'hours_ago'],
                        diff: (d1, d2) => {
                            const diff = d2.getTime() - d1.getTime();
                            return diff / (1000 * 60 * 60);
                        }
                    },
                    {
                        codes: ['minute_ago', 'minutes_ago'],
                        diff: (d1, d2) => {
                            const diff = d2.getTime() - d1.getTime();
                            return diff / (1000 * 60);
                        }
                    }
                ];

                for(const calc of calculators){
                    const diff = Math.trunc(calc.diff(uploadedAt, now));
                    if(diff >= 1){
                        code = (diff > 1)? calc.codes[1] : calc.codes[0];
                        val = diff;
                        break;
                    }
                }
            }

            return this.$t(code, [val]);
        }
    },
    mounted() {
        this.tasks.snapshot = onSnapshot(doc(firestore, `uploads/${this.$route.params.token}`), (doc) => {
            this.file.data = (doc.exists())? doc.data() : false;
            if(!this.init) this._init();
        });
    },
    unmounted() {
        if(this.tasks.snapshot){
            this.tasks.snapshot(); // Unsubscribe
        }
        if(this.tasks.progress_snapshot){
            this.tasks.progress_snapshot(); // Unsubscribe
        }
    }
}
</script>

<style>
.download-container{
    min-height: var(--min-content-height);
    padding-top: 10vh;
    margin-bottom: -4.6rem;
}
.file-type-container{
    position: relative;
}
.file-information{
    min-width: min(24rem, 100%);
}
.file-type-container .file-icon{
    color: var(--yellow);
    width: 11rem;
    height: auto;
}
.file-type-container .file-icon.loading{
    color: var(--background-light-2);
}
.file-type-container .file-type {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0.6rem 1.6rem;
    color: var(--background);
    font-size: 3rem;
    font-weight: bold;
}
.file-type-container .file-type.not-exist{
    right: auto;
    left: 1rem;
    font-size: 5rem;
}
.file-name{
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    padding-bottom: 0.6rem;
    word-break: break-all;
}

.download-uploading-progress-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.8rem;
    width: 16rem;
    border: 0.2rem dotted var(--background-light-2);
    border-radius: 0.5rem;

    animation: download-uploading-progress-container-pulse 3s linear infinite;
}
.download-uploading-progress-container .download-uploading-progress-bar{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: calc(100% + 0.4rem);
    width: calc(100% + 0.4rem);
    margin: -0.2rem;
}
.download-uploading-progress-container .download-uploading-progress-bar .download-uploading-progress{
    height: 100%;
    background-color: var(--background-light-2);
    border-radius: 0.5rem;

    animation: placeholder-glow 3s linear infinite;
}
.download-uploading-progress-container .download-uploading-text{
    position: relative;
    z-index: 1;
    font-size: 0.9rem;
}
@keyframes download-uploading-progress-container-pulse {
    0%, 100%{
        border-color: var(--background-light-2);
    }
    50%{
        border-color: var(--background-light-3);
    }
}
</style>