export default {
    progress: "Progress",
    download: "Download",
    upload_file: "Upload file",
    preparing: "Preparing...",
    almost_ready: "Almost ready...",
    thanks_for_using: "Thanks for using Filez!",
    copyright_suffix: "All rights reserved",
    file_is_uploading: "The file is still being uploaded and will be available in a moment.",
    estimated_time: "Estimated time",
    upload_canceled: "The upload was canceled and is not available.",
    preview: "Preview",
    no_preview: "No preview for this file type available.",
    copied: "Copied",
    ready_to_share: "Download ready to share",
    ready_to_share_description: "The download url can now be shared. If the download is not finished, all information about the progress are also available on the download page.",
    archiving: "Archiving files to a single file...",

    second: "one second",
    seconds: "{0} seconds",
    minute: "one minute",
    minutes: "{0} minutes",
    hour: "one hour",
    hours: "{0} hours",
    day: "one day",
    days: "{0} days",

    minute_ago: "one minute ago",
    minutes_ago: "{0} minutes ago",
    hour_ago: "one hour ago",
    hours_ago: "{0} hours ago",
    day_ago: "one day ago",
    days_ago: "{0} days ago",
    month_ago: "one month ago",
    months_ago: "{0} months ago",
    year_ago: "one year ago",
    years_ago: "{0} year ago",
}