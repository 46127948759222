<template>
    <div class="preview-container">
        <div class="preview-title-container">
            <h2 class="preview-title">{{ $t('preview') }}</h2>
        </div>
        <div class="preview">
            <template v-if="supported.includes(content_type)">
                <template v-if="content_type.startsWith('image/')">
                    <img :src="file_url" :alt="$t('preview')" class="image-background"/>
                    <img :src="file_url" :alt="$t('preview')"/>
                </template>
            </template>
            <div v-else class="d-flex justify-content-center align-items-center w-100 h-100">
                <span class="fs-16 c-dark-gray">{{ $t('no_preview') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Preview",
    props: {
        content_type: String,
        file_url: String
    },
    data: () => ({
        supported: [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "image/webp",
            "image/avif",
            "image/apng"
        ]
    })
}
</script>

<style>
.preview-container{
    position: relative;
    width: 100%;
    height: 20rem;
    border: 0.4rem dotted var(--background-light-1);
    border-radius: 1.2rem;
    padding: 0.5rem;
    margin-top: 1rem;
}
.preview-container .preview-title-container{
    position: absolute;
    z-index: 1;
    top: -1rem;
    left: 50%;
}
.preview-container .preview-title{
    display: inline-block;
    margin: 0;
    margin-left: -50%;
    padding: 0 0.6rem;
    background-color: var(--background);
    border-radius: 0.4rem;
    font-size: 1.2rem;
    color: var(--light-gray);
}
.preview-container .preview{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,25,0.02);
    border-radius: 1.2rem;
    overflow: hidden;
}

.preview-container .preview > img{
    position: relative;
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
}
.preview-container .preview > img.image-background{
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    object-fit: cover;
    filter: blur(1rem);
    opacity: 0.5;
}
</style>