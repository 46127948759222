<template>
    <div class="compiler-progress">
        <div class="compiler-way">
            <div class="compiler-from">
                <div class="position-relative">
                    <FileSvg class="icon"/>

                    <div v-if="from_count" class="compiler-from-count">
                        {{from_count}}
                    </div>
                </div>
            </div>
            <div class="dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <div class="compiler-to">
                <ZipSvg class="icon"/>
            </div>
        </div>
        <div class="compiler-progress-text">
            {{ $t('archiving') }}
            <div v-show="progress !== undefined" class="compiler-progress-progress">
                <div class="compiler-progress-progress-circle">
                    <CircleProgress :percent="progress || 0" :size="50" :border-width="3" :border-bg-width="0" fill-color="var(--yellow)"/>
                </div>
                <div class="compiler-progress-progress-text">
                    <span>{{progress || 0}}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import ZipSvg from "@/components/svg/ZipSvg";
import FileSvg from "@/components/svg/FileSvg";
export default {
    name: "CompilerProgress",
    components: {CircleProgress, FileSvg, ZipSvg},
    props: {
        from_count: undefined,
        progress: undefined
    }
}
</script>

<style>
.compiler-progress{
    position: relative;
    z-index: 1;
}
.compiler-progress .compiler-progress-text{
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.9rem;
    color: var(--dark-gray);
    margin-top: 2rem;
}
.compiler-progress-progress{
    position: relative;
    z-index: 1;
    margin-left: 0.8rem;
}
.compiler-progress-progress-circle{
    transform: scale(-1);
}
.compiler-progress-progress-text{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    font-size: 0.8rem;
}
.compiler-way .dots{
    display: flex;
    align-items: center;
    margin: 0 1rem;
}
.compiler-way .dots .dot{
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--background-light-1);
    border-radius: 50%;
    margin: 0 0.4rem;
    animation: compiler-dot-animation 1.8s linear infinite;
}
.compiler-way .dots .dot:nth-child(2){
    animation-delay: 0.2s;
}
.compiler-way .dots .dot:nth-child(3){
    animation-delay: 0.4s;
}
@keyframes compiler-dot-animation {
    0%, 100%{
        background-color: var(--background-light-1);
    }
    50%{
        background-color: var(--background-light-3);
    }
}
.compiler-way{
    display: flex;
    align-items: center;
}
.compiler-way .compiler-from,
.compiler-way .compiler-to{
    position: relative;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: var(--background-light-2);
    padding: 1rem;
}
.compiler-way .compiler-to::before{
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--yellow);
    border-radius: 50%;
    animation: compiler-to-pulse 2s ease infinite;
}
@keyframes compiler-to-pulse {
    0%{
        opacity: 0.2;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        transform: scale(1.5);
    }
}
.compiler-way .compiler-from .icon,
.compiler-way .compiler-to .icon{
    height: 100%;
    width: auto;
    color: var(--light-gray);
}
.compiler-way .compiler-to .icon{
    color: var(--yellow);
}
.compiler-way .compiler-from .compiler-from-count{
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
    color: var(--background-light-2);
    text-shadow: 0.1rem 0.1rem 0 var(--light-gray), -0.1rem -0.1rem 0 var(--light-gray), 0.1rem -0.1rem 0 var(--light-gray), -0.1rem 0.1rem 0 var(--light-gray);
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
}
</style>