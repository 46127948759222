import axios from "axios";

export default {
    data: () => ({
        mounted: false
    }),
    methods: {
        sleep(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        time(ms = false){
            const time = (new Date()).getTime();
            return (ms)? time : Math.trunc(time/1000);
        },
        random(min, max){
            return Math.floor((Math.random()*((max-min)+1))+min);
        },

        async apiRequest(endpoint, options = {}){
            const r = await axios.request({
                url: `https://europe-west3-filezapp.cloudfunctions.net/api${endpoint}`,
                method: 'get',
                ...options
            }).catch(e => console.log(e));

            if(r && r.data && r.data.success){
                return (r.data.data)? r.data.data : true;
            }
            return false;
        }
    },
    computed: {
        window: function(){
            return window;
        },
        console: function(){
            return console;
        }
    },
    mounted(){
        this.mounted = true;
    }
};
