export default {
    progress: "Fortschritt",
    download: "Herunterladen",
    upload_file: "Datei hochladen",
    preparing: "Bereite vor...",
    almost_ready: "Fast fertig...",
    thanks_for_using: "Danke für's nutzen von Filez!",
    copyright_suffix: "Alle Rechte vorbehalten",
    file_is_uploading: "Die Datei wird noch hochgeladen und ist gleich verfügbar.",
    estimated_time: "Geschätzte Zeit",
    upload_canceled: "Der Upload wurde abgebrochen und ist nicht verfügbar.",
    preview: "Vorschau",
    no_preview: "Keine Vorschau für diesen Dateityp verfügbar.",
    copied: "Kopiert",
    ready_to_share: "Download bereit zum teilen",
    ready_to_share_description: "Der Download-Link kann jetzt geteilt werden. Wenn der Download noch nicht abgeschlossen ist, sind alle Informationen über den Fortschritt auch auf der Download-Seite verfügbar.",
    archiving: "Archiviere alles zu einer Datei...",

    second: "eine Sekunde",
    seconds: "{0} Sekunden",
    minute: "eine Minute",
    minutes: "{0} Minuten",
    hour: "eine Stunde",
    hours: "{0} Stunden",
    day: "ein Tag",
    days: "{0} Tage",

    minute_ago: "vor einer Minute",
    minutes_ago: "vor {0} Minuten",
    hour_ago: "vor einer Stunde",
    hours_ago: "vor {0} Stunden",
    day_ago: "vor einem Tag",
    days_ago: "vor {0} Tagen",
    month_ago: "vor einem Monat",
    months_ago: "vor {0} Monaten",
    year_ago: "vor einem Jahr",
    years_ago: "vor {0} Jahren",
}