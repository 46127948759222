// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, ref as rawStorageRef } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBoGq0YsdOFS5mWYqo2LuOPfVMdqO-AYaY",
    authDomain: "filezapp.firebaseapp.com",
    projectId: "filezapp",
    storageBucket: "cdn.filezapp.com",
    messagingSenderId: "116312642217",
    appId: "1:116312642217:web:cf06daa624eefaacef8fb8",
    measurementId: "G-RP663Y63HS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);
const analytics = getAnalytics(app);

const storageRef = (url) => rawStorageRef(storage, url);

export {
    app,
    analytics,
    auth,
    storage,
    firestore,
    storageRef
}