import { createRouter, createWebHistory } from 'vue-router';

import Upload from "@/views/Upload";
import Download from "@/views/Download";

const routes = [
    {
        path: '/',
        name: 'upload',
        component: Upload
    },
    {
        path: '/download/:token',
        alias: '/d/:token',
        name: 'download',
        component: Download
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
