<template>
    <div class="share-popup">
        <transition name="share-transition">
            <div v-show="show" class="share-popup-box">
                <div class="share-popup-section">
                    <div class="text-center mb-1">
                        <strong class="fs-18 fw-bold c-yellow">{{ $t('ready_to_share') }}</strong>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="input-container">
                            <div class="input-box">
                                <input ref="input" class="cursor-pointer" :value="`https://filezapp.com/d/${token}`" readonly @click="copy">

                                <div v-if="flags.copied" class="input-alert">
                                    <CheckSvg class="icon"/>
                                    {{ $t('copied') }}
                                </div>
                            </div>
                            <div class="input-controls">
                                <button type="button" @click.prevent="copy"><CopySvg/></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="share-popup-section">
                    <p class="fs-14 c-gray m-0">
                        {{ $t('ready_to_share_description') }}
                    </p>
                </div>
            </div>
        </transition>
        <Controls class="small light d-flex justify-content-center mt-2">
            <button type="button" @click="show = !show"><ArrowSvg :style="{transform: (!show)? `rotateZ(180deg)` : `none`}"/></button>
        </Controls>
    </div>
</template>

<script>
import ArrowSvg from "@/components/svg/ArrowSvg";
import Controls from "@/components/Controls";
import CopySvg from "@/components/svg/CopySvg";
import CheckSvg from "@/components/svg/CheckSvg";
export default {
    name: "SharePopup",
    components: {CheckSvg, CopySvg, Controls, ArrowSvg},
    props: {
        token: String
    },
    data: () => ({
        show: false,
        flags: {
            copied: false
        }
    }),
    methods:{
        copy(){
            if(!this.flags.copied){
                const input = this.$refs.input;
                input.focus();
                input.select();
                document.execCommand('copy');

                this.flags.copied = true;
                setTimeout(() => this.flags.copied = false, 2220);
            }
        }
    },
    mounted() {
        this.show = true;
    }
}
</script>

<style>
.input-container{
    position: relative;
    display: flex;
    background-color: var(--background-light-3);
    border: 0.1rem dotted rgba(255,255,25,0.25);
    box-shadow: 0 0 0.75rem rgba(0,0,0,0.2);
}
.input-container,
.input-container .input-box,
.input-container .input-controls button{
    border-radius: 0.3rem;
}
.input-container input,
.input-container .input-controls button{
    appearance: none;
    outline: 0!important;
    border: 0;
    height: 2rem;
}
.input-container .input-box{
    position: relative;
    overflow: hidden;
}
.input-container input{
    background: none;
    color: white;
    font-size: 0.7rem;
    font-family: var(--font);
    padding: 0.5rem 1rem;
    width: 15rem;
    max-width: 100%;
}
.input-container .input-controls{
    display: flex;
}
.input-container .input-controls button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    background-color: var(--yellow);
    transition: 0.1s ease;
}
.input-container .input-controls button:hover{
    cursor: pointer;
    background-color: var(--dark-yellow);
}
.input-container .input-controls button:active{
    transform: scale(0.8);
}
.input-container .input-controls svg{
    height: 1rem;
    width: auto;
}

.input-container .input-alert{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0.4rem 1rem;

    font-size: 0.7rem;
    font-weight: bold;
    color: var(--background);
    background-color: var(--yellow);

    display: flex;
    align-items: center;
    animation: input-alert-animation 2.2s linear forwards;
}
.input-container .input-alert .icon{
    height: 1.4em;
    width: auto;
    margin-right: 0.1rem;
}
@keyframes input-alert-animation {
    0%{
        opacity: 0;
        transform: translateY(-100%) rotateX(45deg);
    }
    8%, 92%{
        opacity: 1;
        transform: translateX(0);
    }
    100%{
        opacity: 0;
        transform: translateY(100%) rotateX(-45deg);
    }
}

.share-popup{
    position: fixed;
    bottom: 1rem;
    left: calc(50% - (min(40rem, 100%) / 2));
    width: 40rem;
    max-width: 100%;
    padding: 0 1rem;
}
.share-popup .share-popup-box{
    position: relative;
    width: 100%;
    background-color: var(--background-light-2);
    border-radius: 1rem;
}
.share-popup .share-popup-section{
    position: relative;
    padding: 0.6rem 1rem;
}
.share-popup .share-popup-section:not(:last-child){
    border-bottom: 1px solid rgba(255,255,255,0.05);
}

.share-transition-enter-active,
.share-transition-leave-active {
    transition: 0.3s ease;
}
.share-transition-enter-from,
.share-transition-leave-to {
    opacity: 0;
    transform: translateY(100%);
}
</style>