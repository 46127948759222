<template>
    <div class="dropdown-option" :title="option.label || null">
        <img v-if="option.img" v-bind="option.img"/>
        <template v-if="option.label">
            {{option.label}}
        </template>

        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "DropdownOption",
    props: {
        option: Object
    }
}
</script>