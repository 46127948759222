<template>
    <div class="background">
        <template v-for="star of stars" :key="star.created_at">
            <FileSvg v-if="star.type === 0" :style="star.style" class="shooting-star"/>
            <FolderSvg v-else-if="star.type === 1" :style="star.style" class="shooting-star"/>
            <ImageSvg v-else-if="star.type === 2" :style="star.style" class="shooting-star"/>
            <VideosSvg v-else-if="star.type === 3" :style="star.style" class="shooting-star"/>
        </template>
    </div>
</template>

<script>
import FileSvg from "@/components/svg/FileSvg";
import FolderSvg from "@/components/svg/FolderSvg";
import ImageSvg from "@/components/svg/ImageSvg";
import VideosSvg from "@/components/svg/VideosSvg";

export default {
    name: "Background",
    components: {VideosSvg, ImageSvg, FileSvg, FolderSvg},
    data: () => ({
        stars: [],
        tasks: {
            shootingStars: null,
            shootingStarsCleanup: null
        }
    }),
    mounted() {
        this.tasks.shootingStars = setInterval(() => {
            if(this.random(0, 1) === 0){
                this.stars.push({
                    type: this.random(0, 3),
                    style: {
                        'top': `${this.random(80, this.$root.body.height-60)}px`,
                        'left': `${this.random(20, this.$root.body.width-20)}px`,
                        'height': `${this.random(2, 8)}px`,
                        '--opacity': this.random(20, 100)/100
                    },
                    created_at: this.time(true)
                });
            }
        }, 100);
        this.tasks.shootingStarsCleanup = setInterval(() => {

            let amount = 0;
            const now = this.time(true);

            for(const star of this.stars){
                if(now-star.created_at > 1800){
                    amount++;
                } else {
                    break;
                }
            }

            this.stars.splice(0, amount);
        }, 2000);
    },
    unmounted() {
        clearInterval(this.tasks.shootingStars);
        clearInterval(this.tasks.shootingStarsCleanup);
    }
}
</script>

<style>
.background{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.background .shooting-star{
    position: absolute;
    animation: shooting-star-animation 1.8s linear forwards;
    color: rgb(255, 255, 25);
    height: 0.6rem;
    width: auto;

    -webkit-filter: drop-shadow(0 0 4px rgb(255, 255, 25));
    filter: drop-shadow(0 0 4px rgb(255, 255, 25));
}
@keyframes shooting-star-animation {
    0%{
        opacity: 0;
    }
    20%, 75%{
        opacity: var(--opacity);
    }
    100%{
        opacity: 0;
    }
}
</style>