<template>
    <div v-show="dragging" ref="container" class="drag-and-drop-container">
        <template v-if="dragging">
            <div v-if="mouse.x > 0 && mouse.y > 0" class="drag-and-drop-pulse-container" :style="{left: `${mouse.x}px`, top: `${mouse.y}px`}">
                <div class="drag-and-drop-pulse"></div>
            </div>

            <transition name="scale-in">
                <div v-show="show.drop_box" class="drag-and-drop-box-container">
                    <div class="drag-and-drop-box">
                        <transition name="scale-in">
                            <div v-show="show.drop_it">
                                <DropSvg class="drop-icon mb-2"/>
                                <div class="fs-30 fw-bold c-white text-uppercase text-center">Drop it</div>
                            </div>
                        </transition>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
import JSZip from "jszip";
import {saveAs} from "file-saver";
import DropSvg from "@/components/svg/DropSvg";
import FileSvg from "@/components/svg/FileSvg";
import ZipSvg from "@/components/svg/ZipSvg";
import CompilerProgress from "@/components/CompilerProgress";
export default {
    name: "DragAndDrop",
    components: {CompilerProgress, ZipSvg, FileSvg, DropSvg},
    data: () => ({
        dragging: false,
        mouse: {
            x: 0,
            y: 0
        },
        show: {
            drop_box: false,
            drop_it: false,
            pulse: false
        }
    }),
    methods: {
        prevent(event){
            event.preventDefault();
            event.stopPropagation();
        },
        onDragOver(event){
            if(this.dragging){
                this.mouse.x = event.pageX;
                this.mouse.y = event.pageY;
            }
        },
        onDragEnter(){
            if(!this.dragging){
                this.dragging = true;
            }
        },
        onDragLeave(event){
            if(event.target.classList.contains('drag-and-drop-container')){
                this.onDragEnd();
            }
        },
        onDragEnd(){
            if(this.dragging){
                this.dragging = false;
            }
        },
        async onDrop(event){
            this.onDragEnd();

            const files = event.dataTransfer.items;
            this.$root.store.files = files;
            this.$router.push({name: 'upload'});
            //const fileTree = await FileTree.create(files);

            //console.log(fileTree);

            /*
            if(files.length > 1){
                const zip = new JSZip();

                for(const file of files){
                    zip.file(file.name, file);
                    console.log(this.time(), file.name);
                }

                zip.generateAsync({type:"blob"}, (event) => {
                    console.log("progression: " + event.percent.toFixed(2) + " %");
                    if(event.currentFile) {
                        console.log("current file = " + event.currentFile);
                    }
                }).then((content) => {
                    console.log(this.time(), "ziped");
                    saveAs(content, "example.zip");
                    console.log(this.time(), "saved");
                });
            }
            */
        }
    },
    watch: {
        'show.drop_box': function(val){
            if(val){
                setTimeout(() => this.show.drop_it = true, 120);
            }
        },
        dragging: function(val){
            if(val){
                setTimeout(() => this.show.drop_box = true, 60);
            } else {
                this.mouse.x = 0;
                this.mouse.y = 0;

                this.show.drop_box = false;
                this.show.drop_it = false;
            }
        }
    },
    mounted() {
        for(const event of ['dragenter', 'dragover', 'dragleave', 'dragend', 'drop']){
            document.addEventListener(event, this.prevent);
        }
        document.addEventListener('dragover', this.onDragOver);
        document.addEventListener('dragenter', this.onDragEnter);
        document.addEventListener('dragleave', this.onDragLeave);
        document.addEventListener('dragend', this.onDragEnd);
        document.addEventListener('drop', this.onDrop);
    },
    unmounted() {
        for(const event of ['dragenter', 'dragover', 'dragleave', 'dragend', 'drop']){
            document.removeEventListener(event, this.prevent);
        }
        document.removeEventListener('dragover', this.onDragOver);
        document.removeEventListener('dragenter', this.onDragEnter);
        document.removeEventListener('dragleave', this.onDragLeave);
        document.removeEventListener('dragend', this.onDragEnd);
        document.removeEventListener('drop', this.onDrop);
    }
}
</script>

<style>
.drag-and-drop-container *{
    pointer-events: none;
}
.drag-and-drop-container{
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 13, 16, 0.97);

    padding: 1.4rem;
}
.drag-and-drop-box-container{
    position: relative;
    width: 100%;
    height: 100%;
    border: 0.5rem dotted var(--yellow);
    border-radius: 2rem;
    padding: 0.6rem;
    animation: drag-and-drop-box-container-animation 3s linear infinite;
}
@keyframes drag-and-drop-box-container-animation {
    0%, 100%{
        border-color: var(--yellow);
    }
    50%{
        border-color: var(--dark-yellow);
    }
}
.drag-and-drop-box{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,25,0.03);
    border-radius: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drag-and-drop-box .drop-icon{
    height: 8rem;
    width: auto;
    color: var(--yellow);
}


.drag-and-drop-pulse-container{
    position: absolute;
    top: 10rem;
    left: 10rem;
}
.drag-and-drop-pulse-container .drag-and-drop-pulse{
    position: absolute;
    left: -6rem;
    top: -6rem;
    width: 12rem;
    height: 12rem;
    background-color: var(--yellow);
    border-radius: 50%;
    animation: drag-and-drop-pulse-animation 2s ease infinite;
}
@keyframes drag-and-drop-pulse-animation {
    0%{
        opacity: 0.8;
        transform: scale(0.05);
    }
    80%, 100%{
        opacity: 0;
        transform: scale(1);
    }
}

.fade-in-enter-active {
    animation: fade-in 0.1s ease;
}
/*
.fade-in-leave-active {
    animation: fade-in 0.1s ease reverse;
}
*/
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.scale-in-enter-active {
    animation: scale-in 0.2s ease;
}
/*
.scale-in-leave-active {
    animation: scale-in 0.2s ease reverse;
}
*/
@keyframes scale-in {
    0% {
        opacity: 0;
        transform: scale(2.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>