<template>
    <Background/>
    <DragAndDrop/>

    <Header/>
    <div class="cntnt">
        <router-view/>
    </div>
    <Footer/>
</template>

<script>
import { auth } from "@/firebase";
import { signInAnonymously, onAuthStateChanged  } from "firebase/auth";
import Header from "@/components/Header";
import Background from "@/components/Background";
import Footer from "@/components/Footer";
import DragAndDrop from "@/components/DragAndDrop";
export default {
    components: {DragAndDrop, Footer, Background, Header},
    data: () => ({
        _auth: null,
        store: {
            files: null
        },
        body: {
            width: 1920,
            height: 1080,
            scroll: 0,
        }
    }),
    created() {
        const updateWindowSize = () => {
            this.body.width = window.innerWidth;
            this.body.height = window.innerHeight;
        }
        const updateWindowScroll = () => {
            this.body.scroll = window.scrollY;
        }

        // Scroll handler
        updateWindowScroll();
        document.addEventListener('scroll', updateWindowScroll);

        // Resize handler
        updateWindowSize();
        const observer = new ResizeObserver(updateWindowSize);
        observer.observe(document.body);

        // Auth handler
        onAuthStateChanged(auth, (user) => {
            if(user){
                this._auth = user;
            } else {
                this._auth = false;
                console.log("Auth error");
            }
        })
        signInAnonymously(auth);
    },
}
</script>

<style>
:root{
    --yellow: rgb(255, 255, 25);
    --dark-yellow: rgb(180, 180, 18);
    --primary: var(--yellow);

    --dark-gray: rgb(106, 110, 115);
    --gray: rgb(145, 153, 161);
    --light-gray: rgb(190, 202, 213);

    --background: rgb(10, 13, 16);
    --background-light-1: rgb(25, 33, 40);
    --background-light-2: rgb(44, 56, 66);
    --background-light-3: rgb(66, 81, 94);

    --header-height: 3rem;
    --min-content-height: calc(100vh - var(--header-height));

    --font: 'Rubik', sans-serif;
}

html, body{
    padding: 0;
    margin: 0;
    font-size: 20px;
    background-color: var(--background);

    min-height: 100%;
    min-width: 300px;
    overflow-x: hidden;

    font-family: 'Rubik', sans-serif;
    color: white;
}

*{
    box-sizing: border-box;
}

::selection {
    color: var(--background);
    background: var(--yellow);
}

#app{
    position: relative;
    min-height: 100vh;
}
.cntnt{
    position: relative;
    z-index: 1;
}

a,
a:link,
a:visited{
    color: inherit;
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 0.5rem;
}
::-webkit-scrollbar-track {
    background: var(--background-light-1);
}
::-webkit-scrollbar-thumb {
    background: var(--background-light-3);
    border-radius: 0.3rem;
}

.fw-light{
    font-weight: 300;
}
.fw-regular{
    font-weight: 400;
}
.fw-medium{
    font-weight: 500;
}
.fw-semibold{
    font-weight: 600;
}
.fw-bold{
    font-weight: 700;
}
.fw-extrabold{
    font-weight: 800;
}
.fw-black{
    font-weight: 900;
}

.fs-14{
    font-size: 0.7rem;
}
.fs-16{
    font-size: 0.8rem;
}
.fs-18{
    font-size: 0.9rem;
}
.fs-20{
    font-size: 1rem;
}
.fs-25{
    font-size: 1.25rem;
}
.fs-30{
    font-size: 1.5rem;
}
.fs-35{
    font-size: 1.75rem;
}
.fs-40{
    font-size: 2rem;
}

.c-gray{
    color: var(--gray)!important;
}
.c-light-gray{
    color: var(--light-gray)!important;
}
.c-dark-gray{
    color: var(--dark-gray)!important;
}
.c-yellow{
    color: var(--yellow)!important;
}
.c-hover-yellow:hover{
    color: var(--yellow)!important;
}
.c-dark-yellow{
    color: var(--dark-yellow)!important;
}

.cursor-pointer{
    cursor: pointer;
}

.limited{
    width: 36rem;
    max-width: 100%;
}

.inline-icon{
    display: inline;
    height: 1em;
    width: auto;
    margin-bottom: -0.1em;
}
</style>